@import "../../theme.scss";

.question-list {

    .primary-btn {
        .btn {
            background-color: $primaryColor;
            border: none;
            width: 100%;
        }
        margin-bottom: 12px;
    }

    .number {
        text-align: center;
        margin-bottom: 12px;
    }
}
