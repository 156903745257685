@import "src/theme.scss";

.add-btn {
    color: white;
    border: none;
    background-color: $primaryColor;
    padding: 24px;
    border-radius: 100%;
    width: 1.4em;
    height: 1.4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;
    // box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
    .add-btn-inner {
        display: flex;
        justify-content: center;
        font-size: 1.4em;
    }
}
