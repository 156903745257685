@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import "theme.scss";

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    overflow: hidden;
}

.page {
    height: 100%;
    background-color: $bgColor;
    display: flex;
    flex-flow: column;
    .header {
        background-color: $primaryColor;
        padding: 10px;
        color: white;
        font-size: 1.2em;
        font-weight: 500;
        text-align: center;
        margin: none;
        flex: 0 1 auto;
    }

    .content {
        padding: 16px;
        flex: 1 1 auto;
        overflow-y: scroll;
        background-color: $bgColor2;

        .card {
            background-color: $bgColor;
            padding: 10px;
            border: none;
            border-radius: 10px;
            margin-bottom: 12px;
        }
        .graph-card {
            .graph {
                height: 35vh;
            }
        }

        .items {
            .item {
                .title {
                    text-align: center;
                    font-size: 1.2em;
                }
                .subtitle {
                    text-align: center;
                    font-size: 0.9em;
                }

                .sections {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 6px 0 12px 0;
                    .section {
                        flex: 1 1 0px;
                        border-radius: 4px;
                        color: white;
                        padding: 10px;

                        .section-title {
                            font-size: 0.7em;
                            font-weight: bold;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        .section-content {
                            text-align: center;
                            font-size: 1.4em;
                        }

                        &.first {
                            margin-right: 8px;
                        }

                        &.second {
                            margin-left: 8px;
                        }

                        &.red {
                            background-color: #dc3545;
                        }
                        &.amber {
                            background-color: #f0ad4e;
                        }
                        &.green {
                            background-color: #28a745;
                        }
                        &.blue {
                            background-color: #007bff;
                        }
                        &.gray {
                            background-color: gray;
                        }
                    }
                }
                .btns {
                    display: flex;
                    .button {
                        flex: 1 1 auto;
                        .btn {
                            width: 100%;
                            border: none;
                        }
                    }
                    .first {
                        margin-right: 5px;
                    }
                    .second {
                        margin-left: 5px;
                    }
                }
            }
        }
        .primary-btn {
            .btn {
                background-color: $primaryColor;
            }
        }
    }
}
