@import "../../theme.scss";

@keyframes window-container {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}

.window-container {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: window-container 0.3s;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .window {
        width: 80%;
        max-height: 80%;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        border-radius: 6px;
        background-color: $bgColor;
        display: flex;
        flex-direction: column;

        .header {
            background-color: $primaryColor;
            padding: 10px;
            color: white;
            font-size: 1.5em;
            font-weight: 500;
            text-align: center;
        }

        .content {
            flex: 1 1 auto;
            padding: 16px;
            overflow-y: scroll;
        }
    }
}
